import React from 'react';
import './LandingPage.css';

function LandingPage() {
  return (
    <div className="landing-page">
      <div className="overlay-box">
        <h1>The Traveller's Tavern Bot</h1>

        <p className="description">
          The Traveller's Tavern Bot is a customisable Twitch bot that provides
          stream management tools, lively viewer engagement, and advanced
          features to enhance your streaming experience.
        </p>

        <div className="button-group">
          <a href="/auth/twitch" className="twitch-button">
            Login with Twitch
          </a>
          <a
            href="https://discord.com/invite/DzvU89QTyz"
            className="discord-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Join The Traveller's Tavern Discord Server
          </a>
        </div>

        <p className="terms">
          By using the Traveller's Tavern Bot, you agree to follow Twitch's
          Community Guidelines and Terms of Service. The bot processes user data
          solely for functionality and does not store personal information
          unnecessarily.
        </p>
        <p className="terms">
          For enquiries, contact me on Discord @NotDelbert.
        </p>
      </div>
    </div>
  );
}

export default LandingPage;
