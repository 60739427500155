import React from 'react';
import './BotSettingsTab.css';

function BotSettingsTab({ settings, setSettings, saveSettings }) {
  if (!settings) {
    return <p>No settings to display.</p>;
  }

  // Handy function to toggle a boolean field within `settings`
  const toggleSetting = (field) => {
    setSettings((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <div className="bot-settings-tab">

      {/* BOT PERSONALITY */}
      <h3>Bot Personality</h3>
      <p className="personality-info">
        Define how your bot speaks, reacts, and behaves. A good prompt helps
        shape the bot’s “character.” For example: 
        <em className="example-text">
          {"You are a friendly Medieval Tavern Keeper, greeting everyone cheerfully with old-fashioned phrases."}
        </em>
      </p>

      <textarea
        className="personality-textarea"
        value={settings.personalityPrompt || ''}
        onChange={(e) =>
          setSettings((prev) => ({
            ...prev,
            personalityPrompt: e.target.value,
          }))
        }
        rows="5"
        placeholder="Enter your bot's personality prompt here..."
      />

      {/* EVENT SETTINGS */}
      <h4>Event Settings</h4>
      <p className="section-note">
        These are the Twitch events your bot can respond to:
      </p>

      <div className="event-settings">
        <label>
          <input
            type="checkbox"
            checked={settings.respondToFollows || false}
            onChange={() => toggleSetting('respondToFollows')}
          />
          Follows
        </label>
        <label>
          <input
            type="checkbox"
            checked={settings.respondToBits || false}
            onChange={() => toggleSetting('respondToBits')}
          />
          Bits (Cheers)
        </label>
        <label>
          <input
            type="checkbox"
            checked={settings.respondToSubscriptions || false}
            onChange={() => toggleSetting('respondToSubscriptions')}
          />
          Subscriptions
        </label>
        <label>
          <input
            type="checkbox"
            checked={settings.respondToGiftedSubs || false}
            onChange={() => toggleSetting('respondToGiftedSubs')}
          />
          Gifted Subs
        </label>
        <label>
          <input
            type="checkbox"
            checked={settings.respondToAds || false}
            onChange={() => toggleSetting('respondToAds')}
          />
          Ads
        </label>
        <label>
          <input
            type="checkbox"
            checked={settings.respondToRaids || false}
            onChange={() => toggleSetting('respondToRaids')}
          />
          Raids
        </label>
        <label>
          <input
            type="checkbox"
            checked={settings.respondToHypeTrain || false}
            onChange={() => toggleSetting('respondToHypeTrain')}
          />
          Hype Train
        </label>
      </div>

      {/* COMMAND SETTINGS */}
      <h4>Command Settings</h4>
      <p className="section-note">
        Choose which chat commands your bot will recognise.
      </p>
      <div className="command-settings">
        <label>
          <input
            type="checkbox"
            checked={settings.respondToShoutout || false}
            onChange={() => toggleSetting('respondToShoutout')}
          />
          Shoutout (!so [Username])
        </label>
        <label>
          <input
            type="checkbox"
            checked={settings.respondToLurk || false}
            onChange={() => toggleSetting('respondToLurk')}
          />
          Lurk (!lurk)
        </label>
        {/* Add more as needed, e.g.:
          <label>
            <input
              type="checkbox"
              checked={settings.respondToEightBall || false}
              onChange={() => toggleSetting('respondToEightBall')}
            />
            8Ball (!8ball)
          </label>
        */}
      </div>

      <button onClick={saveSettings} className="save-button">
        Save Settings
      </button>
    </div>
  );
}

export default BotSettingsTab;
