import React from 'react';
import './HomeTab.css';

function HomeTab({
  user,
  settings,
  botRunning,
  toggleBotEnabled,
  startBot,
  stopBot
}) {
  return (
    <div className="home-tab">
      <h3>Welcome, {user ? user.displayName : 'User'}!</h3>
      <p>Status: {settings.botEnabled ? 'Enabled' : 'Disabled'}</p>

      {/* Toggle bot status */}
      <button
        onClick={toggleBotEnabled}
        disabled={botRunning}
        className="toggle-bot-button"
      >
        {settings.botEnabled ? 'Disable Bot' : 'Enable Bot'}
      </button>

      {/* Start Bot */}
      <button
        onClick={startBot}
        disabled={botRunning}
        className="start-bot-button"
      >
        Start Bot
      </button>

      {/* Stop Bot */}
      <button
        onClick={stopBot}
        disabled={!botRunning}
        className="stop-bot-button"
      >
        Stop Bot
      </button>
    </div>
  );
}

export default HomeTab;
