import React, { useState, useEffect } from 'react';
import './AdminPanel.css'; // optional, or place in Dashboard.css

function AdminPanel() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all users from the server (admin route)
  useEffect(() => {
    async function fetchAllUsers() {
      try {
        const response = await fetch('/api/admin/users'); // We’ll define this route on server
        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          console.error('Failed to load all users:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching all users:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllUsers();
  }, []);

  // Ban or unban a user
  const toggleBanUser = async (userId, banned) => {
    try {
      const response = await fetch('/api/admin/ban', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, banned: !banned })
      });
      if (response.ok) {
        // Update local state
        setUsers((prev) =>
          prev.map((u) =>
            u._id === userId ? { ...u, banned: !banned } : u
          )
        );
      } else {
        console.error('Failed to ban/unban user');
      }
    } catch (error) {
      console.error('Error banning/unbanning user:', error);
    }
  };

  if (loading) return <p>Loading users...</p>;

  return (
    <div className="admin-panel">
      <h2>Admin Panel</h2>
      <table>
        <thead>
          <tr>
            <th>Display Name</th>
            <th>Twitch ID</th>
            <th>User Type</th>
            <th>Banned</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => (
            <tr key={u._id}>
              <td>{u.displayName}</td>
              <td>{u.twitchId}</td>
              <td>{u.userType}</td>
              <td>{u.banned ? 'Yes' : 'No'}</td>
              <td>
                <button onClick={() => toggleBanUser(u._id, u.banned)}>
                  {u.banned ? 'Unban' : 'Ban'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminPanel;
