import React from 'react';
import './Sidebar.css';

function Sidebar({ activeTab, setActiveTab, isOpen, onClose, isAdmin }) {
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    onClose();
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <h2>Tavern Dashboard</h2>
      <ul>
        <li
          className={activeTab === 'home' ? 'active' : ''}
          onClick={() => handleTabClick('home')}
        >
          Home
        </li>
        <li
          className={activeTab === 'botSettings' ? 'active' : ''}
          onClick={() => handleTabClick('botSettings')}
        >
          Bot Settings
        </li>
		
		<li
		  className={activeTab === 'timers' ? 'active' : ''}
		  onClick={() => handleTabClick('timers')}
		>
		  Timers
		</li>

        {/* Only show admin tab if isAdmin === true */}
        {isAdmin && (
          <li
            className={activeTab === 'admin' ? 'active' : ''}
            onClick={() => handleTabClick('admin')}
          >
            Admin Panel
          </li>
        )}
      </ul>
    </div>
  );
}

export default Sidebar;
