// TimerTab.js

import React, { useState, useEffect } from 'react';
import './TimerTab.css';

function TimerTab() {
  const [timers, setTimers] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [newInterval, setNewInterval] = useState(60); // default 60s
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTimers();
  }, []);

  async function fetchTimers() {
    try {
      const res = await fetch('/api/user');
      if (res.ok) {
        const data = await res.json();
        setTimers(data.user.settings.timers || []);
      }
    } catch (error) {
      console.error('Error fetching timers:', error);
    } finally {
      setLoading(false);
    }
  }

  // Create new timer
  async function addTimer() {
    if (!newMessage || !newInterval) return;
    try {
      const res = await fetch('/api/timers', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: newMessage, interval: Number(newInterval) })
      });
      if (res.ok) {
        const data = await res.json();
        setTimers(data.settings.timers);
        setNewMessage('');
        setNewInterval(60);
      } else {
        console.error('Failed to add timer');
      }
    } catch (err) {
      console.error('Error adding timer:', err);
    }
  }

  // Enable/disable timer
  async function toggleTimer(index, enabled) {
    try {
      const res = await fetch(`/api/timers/${index}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ enabled: !enabled })
      });
      if (res.ok) {
        const data = await res.json();
        setTimers(data.settings.timers);
      }
    } catch (err) {
      console.error('Error toggling timer:', err);
    }
  }

  // Delete timer
  async function deleteTimer(index) {
    try {
      const res = await fetch(`/api/timers/${index}`, {
        method: 'DELETE'
      });
      if (res.ok) {
        const data = await res.json();
        setTimers(data.settings.timers);
      }
    } catch (err) {
      console.error('Error deleting timer:', err);
    }
  }

  if (loading) return <p>Loading timers...</p>;

  return (
    <div className="timer-tab">
      <h2>Timer Messages</h2>

      <div className="add-timer">
        <input
          type="text"
          placeholder="Timer message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <input
          type="number"
          placeholder="Interval (seconds)"
          value={newInterval}
          onChange={(e) => setNewInterval(e.target.value)}
        />
        <button onClick={addTimer}>Add Timer</button>
      </div>

      <ul className="timer-list">
        {timers.map((t, i) => (
          <li key={i}>
            <div className="timer-info">
              <span><b>Message:</b> {t.message}</span>
              <span><b>Interval:</b> {t.interval}s</span>
              <span><b>Enabled:</b> {t.enabled ? 'Yes' : 'No'}</span>
            </div>
            <div className="timer-actions">
              <button onClick={() => toggleTimer(i, t.enabled)}>
                {t.enabled ? 'Disable' : 'Enable'}
              </button>
              <button onClick={() => deleteTimer(i)}>Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TimerTab;
