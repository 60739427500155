import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import HomeTab from './HomeTab';
import BotSettingsTab from './BotSettingsTab';
import TimerTab from './TimerTab'; // new file
import AdminPanel from './AdminPanel'; // NEW Admin Panel component
import './Dashboard.css';

function Dashboard() {
  const [activeTab, setActiveTab] = useState('home');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // user data
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // track admin status
  const [loading, setLoading] = useState(true);
  const [botRunning, setBotRunning] = useState(false);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await fetch('/api/user');
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setSettings(data.user.settings);
          setIsAdmin(data.user.userType === 'admin'); // Check if user is admin
          await checkBotStatus(data.user.id);
        } else {
          console.error('Failed to load user data:', response.statusText);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    }
    fetchUserData();
  }, []);

  const checkBotStatus = async (userId) => {
    try {
      const response = await fetch(`/api/bot/status/${userId}`);
      if (response.ok) {
        const data = await response.json();
        setBotRunning(data.isRunning);
      } else {
        console.error('Failed to check bot status:', response.statusText);
      }
    } catch (error) {
      console.error('Error checking bot status:', error);
    } finally {
      setLoading(false);
    }
  };

  // Toggle botEnabled
  const toggleBotEnabled = async () => {
    if (!settings) return;
    const updatedBotEnabled = !settings.botEnabled;
    try {
      const response = await fetch('/api/settings/bot-enabled', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ botEnabled: updatedBotEnabled })
      });
      const data = await response.json();
      if (response.ok) {
        setSettings((prev) => ({
          ...prev,
          botEnabled: updatedBotEnabled
        }));
      } else {
        alert('Failed to update bot status.');
      }
    } catch (error) {
      alert('An unexpected error occurred while updating bot status.');
    }
  };

  // Save entire settings object
  const saveSettings = async () => {
    if (!settings) return;
    try {
      const response = await fetch('/api/settings', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(settings)
      });
      const data = await response.json();
      if (response.ok) {
        setSettings(data.settings);
        alert('Settings updated successfully!');
      } else {
        alert(`Error saving settings: ${data.error}`);
      }
    } catch (error) {
      alert('An unexpected error occurred while saving settings.');
    }
  };

  // Start the bot
  const startBot = async () => {
    try {
      const response = await fetch('/api/bot/start', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      });
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Unknown error occurred');
      }
      setBotRunning(true);
    } catch (error) {
      alert('Failed to start bot.');
    }
  };

  // Stop the bot
  const stopBot = async () => {
    try {
      if (!user || !user.id) {
        alert('User ID is not available.');
        return;
      }
      const response = await fetch('/api/bot/stop', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.id })
      });
      const data = await response.json();
      if (response.ok) {
        setBotRunning(false);
        alert('Bot stopped successfully!');
      } else {
        alert(`Error stopping bot: ${data.error}`);
      }
    } catch (error) {
      alert('An unexpected error occurred while stopping the bot.');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (!settings) return <p>Error loading settings.</p>;

  return (
    <div className="dashboard-container">
      <div className="hamburger-icon" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <Sidebar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        isAdmin={isAdmin} // pass isAdmin to Sidebar
      />

      <div className="dashboard-content">
        {activeTab === 'home' && (
          <HomeTab
            user={user}
            settings={settings}
            botRunning={botRunning}
            toggleBotEnabled={toggleBotEnabled}
            startBot={startBot}
            stopBot={stopBot}
          />
        )}
        {activeTab === 'botSettings' && (
          <BotSettingsTab
            settings={settings}
            setSettings={setSettings}
            saveSettings={saveSettings}
          />
        )}
		{activeTab === 'timers' && <TimerTab />}
        {activeTab === 'admin' && isAdmin && (
          <AdminPanel />
        )}
      </div>
    </div>
  );
}

export default Dashboard;
